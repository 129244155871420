






























































































import Vue from 'vue';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import { commonModule } from '@/store/viewModule/common/commonModule';
import LocalDataService from '@/service/LocalDataServices';
import { isMobile } from '@/service/StyleService';
import { UserResponse } from 'remonade-api/lib';
import { gaSetUserId } from '@/service/GoogleAnalyticsService';

interface DataType {
  teamId: string;
  teamName: string;
  username: string;
  firstName: string;
  lastName: string;
  isValid: boolean;
}

export default Vue.extend({
  name: 'CreateTeamContainer',

  data: (): DataType => {
    return {
      teamId: '',
      teamName: '',
      username: '',
      firstName: '',
      lastName: '',
      isValid: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
      };
    },
    isMobile(): boolean {
      return isMobile(window);
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    async createTeam() {
      const locale = this.$i18n.locale;
      commonModule.setLoader(true);
      auth0Module.createTeam({
        teamId: this.teamId,
        teamName: this.teamName,
        username: this.username,
        firstName: this.firstName,
        lastName: this.lastName,
        language: locale,
      }).then(async (data) => {
        commonModule.setLoader(false);
        const response = data;
        if (response.teamId && response.userId) {
          this.selectTeam(response);
        } else if (data.error) {
          switch (data.error) {
            case 'E005':
              alert(this.$t('errorE005'));
              break;
            case 'E020':
              alert(this.$t('errorE020'));
              break;
            case 'E038':
              alert(this.$t('errorE038'));
              break;
            case 'E039':
              alert(this.$t('errorE039'));
              break;
            case 'E040':
              alert(this.$t('errorE040'));
              break;
            case 'E041':
              alert(this.$t('errorE041'));
              break;
            case 'E042':
              alert(this.$t('errorE042'));
              break;
            case 'E043':
              alert(this.$t('errorE043'));
              break;
            default:
              alert(this.$t('errorAuthAlert'));
              break;
          }
        }
      }).catch((e) => {
        console.log(e)
        commonModule.setLoader(false);
        alert(this.$t('errorSignup'));
      });
    },
    async selectTeam(account: UserResponse) {
      LocalDataService.setTeamId(account.teamId);
      LocalDataService.setUserId(account.userId);
      this.handleRedirect(account);
    },
    async handleRedirect(user: UserResponse) {
      const teamId = user.teamId;
      LocalDataService.setUser(user);
      LocalDataService.setTeamId(user.teamId);
      commonModule.setLoader(false);
      // this.$ga.set('userId', user.userId);
      gaSetUserId(user.userId, user.userType);
      if (user.isFirstLogin) {
        this.$router.push({ name: 'onboarding'});
      } else {
        this.$router.push({ name: 'today'});
      }
    },
    backToSignIn() {
      this.$router.push({ name: 'signin' });
    },
  },

  mounted() {
    auth0Module.initClient();
  }
});
