



import Vue from 'vue';
import CreateTeamContainer from '@/container/auth/CreateTeamContainer.vue';

export default Vue.extend({
  name: 'CreateTeamScene',

  components: {
    CreateTeamContainer,
  },
});
